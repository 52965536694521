import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ReactGA from "react-ga";
import { useCookies } from "react-cookie";
import { useUiState, useDispatchUiState } from "./context/UiContext";

import useWindowSize from "./hooks/useWindowSize";

import Theme from "./Theme";
import Layout from "./Layout";
import CookieBanner from "./components/base/CookieBanner";
import ManageCookies from "./components/base/ManageCookies";

import "./App.css";

import { UPDATE_AFTER_WINDOW_SIZE_CHANGED } from "./context/uiStateTypes";

import { handleCookieConsentResponse } from "./utils/handleCookieConsentResponse";

const CONSENT_COOKIE_NAME = "k__io_analytics-permission";
const GOOGLE_ANALYTICS_CODE = "G-571HYT0EQ1";
const App = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [manageCookies, setManageCookies] = useState(false);
  const uiState = useUiState();
  const dispatchUiState = useDispatchUiState();
  const windowSize = useWindowSize();

  const cookiesAccepted = cookies[CONSENT_COOKIE_NAME] === "accepted";
  const cookiesSet = cookies[CONSENT_COOKIE_NAME];
  const handleCookieConsentChoice = (choice) => {
    handleCookieConsentResponse({
      choice,
      setManageCookies,
      setCookie,
      removeCookie,
    });
  };

  useEffect(() => {
    if (cookiesAccepted) {
      ReactGA.initialize(GOOGLE_ANALYTICS_CODE);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [cookies, cookiesAccepted]);

  useEffect(() => {
    if (windowSize.width) {
      dispatchUiState({
        type: UPDATE_AFTER_WINDOW_SIZE_CHANGED,
        payload: {
          windowSize,
        },
      });
    }
  }, [dispatchUiState, windowSize]);

  useEffect(() => {
    if (document.activeElement.nodeName === "IFRAME") {
      document.activeElement.blur();
    }
  }, []);
  const showCookieBanner = !cookiesSet && !uiState.screenshotMode;
  return (
    <Container>
      <Theme uiState={uiState}>
        {showCookieBanner && (
          <CookieBanner handleCookieConsentChoice={handleCookieConsentChoice} />
        )}
        {manageCookies && (
          <ManageCookies
            handleCookieConsentChoice={handleCookieConsentChoice}
            setManageCookies={setManageCookies}
          />
        )}
        {windowSize.width > 0 && <Layout />}
      </Theme>
    </Container>
  );
};

export default App;

const Container = styled.div`
  display: flex;
  width: 100%;
`;
