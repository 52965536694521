import React from "react";
import styled from "styled-components";
import routes from "../../data/routes";
import { useNavigate } from "react-router-dom";

import { Close as CloseIcon } from "@styled-icons/material/Close";
import { AccountBox as AccountIcon } from "@styled-icons/material/AccountBox";
import { useUser } from "../../context/UserContext";

import UserMenu from "./UserMenu";

import IconWrapper from "../common/IconWrapper";

const SideDrawer = ({ sideDrawerOpen, toggleSideDrawer }) => {
  const { user } = useUser();
  const navigate = useNavigate();

  const handleLinkClick = ({ href }) => {
    navigate(href);
    toggleSideDrawer();
  };
  return (
    <Container show={sideDrawerOpen}>
      <CloseIconWrapper
        colour="white"
        size={30}
        onClick={() => toggleSideDrawer(false)}
        clickable
      >
        <CloseIcon />
      </CloseIconWrapper>
      <LinksSection>
        <MainLinks>
          {routes
            .filter((r) => !r.hideInHeader && !r.showInChartsMenu)
            .map((route) => {
              return (
                <LinkRow key={route.href}>
                  <MainLink
                    onClick={() => handleLinkClick({ href: route.href })}
                  >
                    {route.label}
                  </MainLink>
                </LinkRow>
              );
            })}
        </MainLinks>
        <Divider />
        <MainLinks>
          {routes
            .filter((r) => r.showInChartsMenu)
            .map((route) => {
              return (
                <LinkRow key={route.href}>
                  <MainLink
                    onClick={() => handleLinkClick({ href: route.href })}
                  >{`${route.label} chart`}</MainLink>
                </LinkRow>
              );
            })}
          <ChartsWarning>Best viewed on desktop</ChartsWarning>
        </MainLinks>
        <Divider />
        {user ? (
          <AvatarImage
            avatarImage={user.picture}
            alt="avatar"
            title="you are logged in"
          />
        ) : (
          <AccountIconWrapper colour="white" size={30}>
            <AccountIcon />
          </AccountIconWrapper>
        )}
        <UserMenu user={user} closeMenu={() => toggleSideDrawer(false)} />
        <Divider />
        <OtherLinks>
          {routes
            .filter((r) => r.hideInHeader && !r.hideInSideDrawer)
            .map((route) => {
              return (
                <LinkRow key={route.href}>
                  <StyledLink
                    onClick={() => handleLinkClick({ href: route.href })}
                  >
                    {route.label}
                  </StyledLink>
                </LinkRow>
              );
            })}
        </OtherLinks>
      </LinksSection>
    </Container>
  );
};

export default SideDrawer;

const Container = styled.div`
  display: flex;
  color: ${(props) => props.theme.colours.navbarColour};
  background: ${(props) => props.theme.colours.navbarBackground};
  position: fixed;
  top: 0;
  right: 0;
  width: 70%;
  max-width: 400px;
  z-index: 80000;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  transform: ${(props) => (props.show ? "translateX(0)" : "")};
  font-size: 25px;
  border-bottom-left-radius: 8px 8px;
`;

const AvatarImage = styled.div`
  display: flex;
  width: 25px;
  height: 25px;
  background-image: url(${(props) => props.avatarImage});
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0 auto 15px auto;
`;

const AccountIconWrapper = styled(IconWrapper)`
  margin: 0 auto 10px auto;
`;

const CloseIconWrapper = styled(IconWrapper)`
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Divider = styled.div`
  border-top: 1px solid ${(props) => props.theme.colours.whiteOpacity35};
  margin: 10px;
`;

const LinksSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 100%;
  padding-bottom: 10px;
`;

const MainLinks = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const OtherLinks = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: 0.9;
`;

const LinkRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 5px;
`;

const ChartsWarning = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 5px;
  font-size: 16px;
  opacity: 0.8;
`;

const MainLink = styled.div`
  display: flex;
  color: ${(props) => props.theme.colours.navbarColour};
  text-transform: uppercase;
  cursor: pointer;
`;

const StyledLink = styled.div`
  display: flex;
  color: ${(props) => props.theme.colours.navbarColour};
  cursor: pointer;
`;
