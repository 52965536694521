const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const uiConfig = {
  commentaryLightMode: {
    mainBackground: "#FAF9F6",
    mainTextColour: "#181818",
    navBackground: "#fff",
    navTextColour: "black",
    buttonBackground: "#fff",
    buttonTextColour: "black",
    buttonBorder: "#bbb",
    buttonBorderActive: "#aaa",
    modalBackground: "black",
    modalText: "gray",
    tooltipText: "#aaa",
    modalWarningText: "red",
    menuBackground: "blue",
    tabSelectedBackground: "#181818",
    tabSelectedBorder: "blue",
    tabBackground: "#111827",
    subTabBackground: "#1f2937",
    subTabSelectedBackground: "#374151",
    subTabSelectedBorder: "#71717a",
  },

  commentaryDarkMode: {
    mainBackground: "#181818",
    mainTextColour: "#fdfdfd",
    navBackground: "#2d2d2d",
    navTextColour: "#eee",
    buttonBackground: "#313131",
    buttonTextColour: "white",
    buttonBorder: "#eee",
    buttonBorderActive: "#ddd",
    buttonBorderInactive: "rgba(255,255,255,.20)",
    modalBackground: "#2d2d2d",
    modalBackgroundStripe: "rgba(0,0,0,.4)",
    modalText: "white",
    tooltipText: "#fdfdfd",
    tooltipBorder: "#fdfdfd",
    modalWarningText: "red",
    modalDivider: "rgba(255,255,255,.20)",
    modalBorder: "rgba(0,0,0,.4)",
    whiteOpacity20: "rgba(255,255,255,.20)",
    menuBackground: "rgba(0,0,0,.80)",
    tabSelectedBackground: "#1f2937",
    tabSelectedBorder: "#71717a",
    tabBackground: "#111827",

    subTabBackground: "#1f2937",
    subTabSelectedBackground: "#374151",
    subTabSelectedBorder: "#71717a",
  },

  device: {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    inBetween: `(min-device-width: ${size.mobileL}) and (max-device-width: ${size.tablet})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`,
  },

  commentaryFontSizes: {
    text1: 34,
    text2: 33,
    text3: 32,
    text4: 30,
    text5: 26,
    text6: 22,
    text7: 18,
    text8: 16,
    text9: 14,
    text10: 12,
  },

  modal: { container: "#", row: "r", header: "h" },
  menuIconsPadding: "8px",
  colours: {
    tabBackground: "#111827",
    tabSelectedBackground: "#1f2937",
    tabSelectedBorder: "#71717a",

    subTabBackground: "#1f2937",
    subTabSelectedBackground: "#374151",
    subTabSelectedBorder: "#71717a",

    rowBackground: "rgba(0,0,0,.2)",
    rowBackgroundAccent: "rgba(0,0,0,.4)",

    onScreenBackground: "rgba(0,0,0,.9)",

    mainBackground: "#181818",
    mainColour: "#fdfdfd",
    navbarBackground: "#2d2d2d",
    mainAccentBackground: "#2d2d2d",
    darkAccentBackground: "#212121",

    matchPanelBackground: "rgba(0,0,0,.95)",

    menuDividerBackground: "rgba(255,255,255,.25)",

    navbarColour: "#fdfdfd",
    navbarBackground90: "rgba(45,45,45,0.9)",
    inputBackground: "#202020",
    featureColour: "#720126",
    sliderTrack: "#aaaaaa",
    sliderThumb: "#181818",
    buttonBackground: "#313131",
    mainPageBackground: "#rgba(0,0,0,.4)",
    // mainPageBackground: "rgba(0,0,0,.2)",
    tabHeaderColour: "rgba(255,255,255,.60)",
    tabHeaderColourActive: "rgba(255,255,255,.90)",
    tabHeaderColourHover: "rgba(255,255,255,.80)",

    mainSection: "#5e5e5e",
    menuIconColour: "#bbb",
    menuIconActiveColour: "#fff",
    menuIconHoverColour: "#eee",
    menuIconBackground: "",
    menuIconActiveBackground: "rgba(20,29,38,.95)",
    modalContainerBackground: "rgb(20,29,38, 0.95)",
    modalContainerBackgroundDark: "rgb(20,29,38, 1)",
    panelRowBackgroundAccent: "rgba(0,0,0,.2)",
    panelRowBackgroundHover: "rgba(0,0,0,.2)",
    viewPanelMenuBackground: "rgba(20,29,38,.8)",
    viewPanelMenuBackgroundHover: "rgba(20,29,38.90)",
    headerBottomLine: "rgba(255,255,255,.50)",
    mainMatchColour: "#fff",
    mainColour5: "rgb(76, 106, 146, 0.05)",
    mainColour10: "rgb(76, 106, 146, 0.1)",
    mainColour20: "rgb(76, 106, 146, 0.2)",
    mainColour50: "rgb(76, 106, 146, 0.5)",
    footerBackground: "#4c6a92",
    matchVideoBackground: "rgba(23,23,23,0.9)",
    matchContainerBackground: "#312e2b",

    panelBackground: "#141d26",
    panelBackgroundAccent: "#243447",
    matchHeaderIconColour: "rgba(255,255,255,.80)",
    matchHeaderIconHoverColour: "rgba(255,255,255,.90)",
    tableRowBackground: "#354b68",
    tableRowBackgroundHover: "#26364a",
    tableButtonBackgroundActive: "#b4c3d8",
    tableButtonBackground: "",
    tableButtonColourActive: "#2e4059",
    tableRowColour: "#fff",
    tableButtonColour: "#fff",
    tableButtonColourHover: "#2e4059",
    tableButtonBackgroundHover: "#b4c3d8",
    navigateItemColour: "#4c6a92",
    navigateItemBackground: "",
    navigateItemColourHover: "#fff",
    navigateItemBackgroundHover: "#b4c3d8",
    pendingSubsBackground: "rgba(0,0,0,.65)",
    sliderPanelBackground: "rgba(0,0,0,.90)",
    viewPanelRowBackground: "rgba(0,0,0,.60)",
    inputActionBackground: "rgba(0, 0, 0, 0.7)",
    panelCommandTextColour: "white",
    panelCommandBackground: "rgba(0, 0, 0, 0.95)",
    modalTextShadow: "0 0 2px rgba(0,0,0,.5)",
    menuBackground: "rgba(0,0,0,.7)",
    menuBackgroundDark: "#303641",
    menuBackgroundActive: "#14181f",
    button: "rgba(255,255,255,.15)",
    buttonActive: "rgba(255,255,255,.35)",
    highlight: "#EC5D44",
    highLightActive: "#ff401f",
    darkBackground: "#333333",
    darkerBackground: "#1d1d1d",
    textColours: ["#666", "#aaa", "#bbb"],
    pageBackround: "#D2D1CD",
    lightgray: "lightgray",
    gray: "gray",
    white: "white",
    black: "black",
    whiteOpacity5: "rgba(255,255,255,.05)",
    whiteOpacity10: "rgba(255,255,255,.10)",
    whiteOpacity20: "rgba(255,255,255,.20)",
    whiteOpacity35: "rgba(255,255,255,.35)",
    whiteOpacity40: "rgba(255,255,255,.40)",
    whiteOpacity60: "rgba(255,255,255,.60)",
    whiteOpacity80: "rgba(255,255,255,.80)",
    blackOpacity10: "rgba(0,0,0,.10)",
    blackOpacity20: "rgba(0,0,0,.20)",
    blackOpacity40: "rgba(0,0,0,.40)",
    blackOpacity60: "rgba(0,0,0,.60)",
    blackOpacity80: "rgba(0,0,0,.80)",
    blackOpacity90: "rgba(0,0,0,.90)",
    blackOpacity95: "rgba(0,0,0,.95)",
    grayOpacity60: "rgba(128,128,128,.60)",
    redOpacity80: "rgba(255,0,08.80)",
    panelBodyBackground: "rgba(0,0,0,.4)",
    buttonsRowBackground: "rgba(0,0,0,.9)",
    panelRowBackground: "rgba(0,0,0,.9)",
    panelRowBackgroundActive: "rgba(0,0,0,.95)",
    opactity0: "rgba(0,0,0,0)",
    excelTableBackground: "transparent",
    excelTableTextColour: "white",
  },
  layout: {
    maxAppWidth: 1000,
    menuHeight: 80,
    onScreenMenuHeight: 80,
    mainPanelWidth: 700,
  },
  wizzard: {
    containerWidth: 525,
    inputWindowHeight: 105,
    wizzardInputFontSize: 12,
    genderIconSize: 12,
    playerNumberFontSize: 20,
    teamTokenFontSize: 12,
    commandLabelFontSize: 16,
    containerBackground: "rgba(0,0,0,.75)",
    statusContainerHeight: 40,
  },
};
