import { useReducer } from "react";

import {
  // SET_SHOW_FULL_VIDEO,
  // CLOSE_NAVIGATION_CONTROLLER,
  // HANDLE_NAVIGATION_ICON_CLICK,
  // HANDLE_NAVIGATION_TEAM_CLICK,
  // HANDLE_VIEW_ICON_CLICK,
  // SET_PANEL_MENU_OPTION,
  // CREATE_MODAL_OBJECT,
  // REMOVE_MODAL_OBJECT,
  // TOGGLE_MODAL_OBJECT,
  // UPDATE_MODAL_OBJECT_BY_FUNCTION,
  // TOGGLE_SHOW_HELP,
  // SET_SHOW_TUTORIAL_MODE,
  // TOGGLE_SHOW_ALL_DIVISIONS,
  // SET_SCREENSHOT_MODE,
  // SET_SHOW_MAIN_MENU,
  // SET_ELEMENT_POSITION,
  // SET_VIDEO_POSITION,
  // TOGGLE_SHOW_TOOLTIPS,
  SET_LIVE_CAPTURE_MATCH_TAB_ID,
  UPDATE_AFTER_WINDOW_SIZE_CHANGED,
  CANCEL_UI_COMPONENTS,
  TOGGLE_ACTIONS_TABLE,
  TAB_PANEL_LEFT,
  TAB_PANEL_RIGHT,
  SET_MATCH_VIEW_MODE,
  SET_PAGE_MODE,
  SET_SELECTED_TAB_ID,
  SET_SELECTED_SUB_TAB_ID,
  SET_SELECTED_SUB_TAB_FILTER,
  SET_SELECTED_DASHBOARD_TAB_ID,
  SET_SELECTED_DASHBOARD_SUB_TAB_ID,
  SET_SELECTED_DASHBOARD_SUB_TAB_FILTER,
  SET_DIVISIONS_FILTER,
  SET_CAPTURE_MODE,
  HIDE_WIZZARD,
  SHOW_ON_SCREEN_PANEL,
  TOGGLE_SHOW_SKIP_SETTINGS,
  TOGGLE_SHOW_NAVIGATION_SELECTOR,
  TOGGLE_SHOW_WIZZARD_MENU,
  TOGGLE_SHOW_PENDING_SUBS,
  TOGGLE_SHOW_EDIT_ACTION_PANEL,
  TOGGLE_SHOW_KEYBOARD_SHORTCUTS,
  HIDE_TOP_LEVEL_MATCH_TABS,
  SET_SELECTED_COMMENTARY_MATCH_SUB_TAB_ID,
  SET_SELECTED_COMMENTARY_MATCH_TAB_ID,
  SET_SELECTED_COMPETITION_TAB_ID,
  SET_DATA_TYPE_TAB_ID,
} from "./uiStateTypes";

import { defaultUiState } from "../data/uiState";

const closeAllOnScreenModalContainers = {
  showPendingSubs: false,
  showWizzardMenu: false,
  showKeyboardShortcuts: false,
  showEditActionPanel: false,
  editActionPanelActionId: null,
  showOnScreenSidePanel: false,
};

export default function useUiStateHook() {
  const [state, dispatch] = useReducer(reducer, defaultUiState);

  function reducer(state, action) {
    switch (action.type) {
      case SET_LIVE_CAPTURE_MATCH_TAB_ID: {
        return {
          ...state,
          liveCaptureMatchTabs: state.liveCaptureMatchTabs.map((tab) => {
            return { ...tab, selected: tab.tabId === action.payload.tabId };
          }),
        };
      }
      case SET_DATA_TYPE_TAB_ID: {
        return {
          ...state,
          dataTypeTabs: state.dataTypeTabs.map((tab) => {
            return { ...tab, selected: tab.tabId === action.payload.tabId };
          }),
        };
      }
      case SET_SELECTED_COMPETITION_TAB_ID: {
        const tabType = action.payload.tabType;
        return {
          ...state,
          [tabType]: state[tabType].map((tab) => {
            return { ...tab, selected: tab.tabId === action.payload.tabId };
          }),
        };
      }

      case TOGGLE_SHOW_EDIT_ACTION_PANEL: {
        return {
          ...state,
          ...closeAllOnScreenModalContainers,
          showEditActionPanel: true,
          editActionPanelActionId: action.payload.editActionId,
        };
      }
      case TOGGLE_SHOW_KEYBOARD_SHORTCUTS:
        return {
          ...state,
          ...closeAllOnScreenModalContainers,
          showKeyboardShortcuts: !state.showKeyboardShortcuts,
        };
      case TOGGLE_SHOW_WIZZARD_MENU:
        return {
          ...state,
          ...closeAllOnScreenModalContainers,
          showWizzardMenu: !state.showWizzardMenu,
        };
      case TOGGLE_SHOW_PENDING_SUBS:
        return {
          ...state,
          ...closeAllOnScreenModalContainers,
          showPendingSubs: !state.showPendingSubs,
        };
      case SET_CAPTURE_MODE:
        return { ...state, captureMode: action.payload.captureMode };
      case SET_DIVISIONS_FILTER:
        return { ...state, divisionsFilter: action.payload.divisionLabel };
      case TOGGLE_SHOW_SKIP_SETTINGS:
        return { ...state, showSkipSettings: !state.showSkipSettings };
      case TOGGLE_SHOW_NAVIGATION_SELECTOR:
        return {
          ...state,
          showNavigationSelector: !state.showNavigationSelector,
        };
      case HIDE_TOP_LEVEL_MATCH_TABS:
        return {
          ...state,
          hideMatchPanelTopLevelTabs: action.payload.hideMatchPanelTopLevelTabs,
        };

      case SET_SELECTED_COMMENTARY_MATCH_TAB_ID:
        return {
          ...state,
          commentaryMatchTabs: state.commentaryMatchTabs.map((tab) => {
            return { ...tab, selected: tab.tabId === action.payload.tabId };
          }),
        };
      case SET_SELECTED_COMMENTARY_MATCH_SUB_TAB_ID:
        return {
          ...state,
          commentaryMatchTabs: state.commentaryMatchTabs.map((tab) => {
            return { ...tab, selected: tab.tabId === action.payload.tabId };
          }),
        };

      case SET_SELECTED_DASHBOARD_TAB_ID:
        return {
          ...state,
          dashboardTabs: state.dashboardTabs.map((tab) => {
            return { ...tab, selected: tab.tabId === action.payload.tabId };
          }),
        };
      case SET_SELECTED_DASHBOARD_SUB_TAB_ID:
        return {
          ...state,
          dashboardTabs: [
            ...state.dashboardTabs.map((tab) => {
              return {
                ...tab,
                selected: tab.tabId === action.payload.tabId,
                subTabs: tab.subTabs.map((subTab) => {
                  return {
                    ...subTab,
                    selected:
                      tab.tabId === action.payload.tabId
                        ? subTab.subTabId === action.payload.subTabId
                          ? true
                          : false
                        : subTab.selected,
                  };
                }),
              };
            }),
          ],
        };
      case SET_SELECTED_DASHBOARD_SUB_TAB_FILTER:
        return {
          ...state,
          dashboardTabs: [
            ...state.dashboardTabs.map((tab) => {
              return {
                ...tab,
                selected: tab.tabId === action.payload.tabId,
                subTabs: tab.subTabs.map((subTab) => {
                  return {
                    ...subTab,
                    selected:
                      tab.tabId === action.payload.tabId
                        ? subTab.subTabId === action.payload.subTabId
                          ? true
                          : false
                        : subTab.selected,
                    activeSubTabFilter:
                      tab.tabId === action.payload.tabId &&
                      subTab.subTabId === action.payload.subTabId
                        ? action.payload.activeSubTabFilter
                        : subTab.activeSubTabFilter,
                  };
                }),
              };
            }),
          ],
        };
      case SET_SELECTED_TAB_ID:
        return {
          ...state,
          tabs: state.tabs.map((tab) => {
            return { ...tab, selected: tab.tabId === action.payload.tabId };
          }),
        };
      case SET_SELECTED_SUB_TAB_ID:
        return {
          ...state,
          tabs: [
            ...state.tabs.map((tab) => {
              return {
                ...tab,
                selected: tab.tabId === action.payload.tabId,
                subTabs: tab.subTabs.map((subTab) => {
                  return {
                    ...subTab,
                    selected:
                      tab.tabId === action.payload.tabId
                        ? subTab.subTabId === action.payload.subTabId
                          ? true
                          : false
                        : subTab.selected,
                  };
                }),
              };
            }),
          ],
        };
      case SET_SELECTED_SUB_TAB_FILTER:
        return {
          ...state,
          tabs: [
            ...state.tabs.map((tab) => {
              return {
                ...tab,
                selected: tab.tabId === action.payload.tabId,
                subTabs: tab.subTabs.map((subTab) => {
                  return {
                    ...subTab,
                    selected:
                      tab.tabId === action.payload.tabId
                        ? subTab.subTabId === action.payload.subTabId
                          ? true
                          : false
                        : subTab.selected,
                    activeSubTabFilter:
                      tab.tabId === action.payload.tabId &&
                      subTab.subTabId === action.payload.subTabId
                        ? action.payload.activeSubTabFilter
                        : subTab.activeSubTabFilter,
                  };
                }),
              };
            }),
          ],
        };
      case SET_MATCH_VIEW_MODE: {
        const { matchViewMode } = action.payload;
        const {
          windowWidth,
          windowHeight,
          matchSidePanelWidth,
          showContentInColumnBreakpoint,
        } = state;
        const disableOnScreenMode = windowWidth < 1000;
        const showContentInColumn =
          disableOnScreenMode ||
          windowWidth - matchSidePanelWidth < showContentInColumnBreakpoint;

        const availableWidth =
          matchViewMode === "withSidePanel" && !showContentInColumn
            ? windowWidth - matchSidePanelWidth
            : windowWidth;
        const availableHeight = windowHeight;
        let videoWidth = availableWidth;
        let videoHeight = availableHeight;
        if ((videoWidth * 450) / 800 > availableHeight) {
          videoWidth = (videoHeight * 800) / 450;
        } else {
          videoHeight = (videoWidth * 450) / 800;
        }

        return {
          ...state,
          matchViewMode,
          videoHeight,
          videoWidth,
          showContentInColumn,
        };
      }
      case SET_PAGE_MODE:
        return { ...state, pageMode: action.payload.pageMode };
      case UPDATE_AFTER_WINDOW_SIZE_CHANGED: {
        const { height, width, scrollHeight } = action.payload.windowSize;
        const {
          matchViewMode,
          matchSidePanelWidth,
          showContentInColumnBreakpoint,
        } = state;

        const disableOnScreenMode = width < 1000;
        const showContentInColumn =
          disableOnScreenMode ||
          width - matchSidePanelWidth < showContentInColumnBreakpoint;

        const matchAvailableWidth =
          matchViewMode === "withSidePanel" && !showContentInColumn
            ? width - matchSidePanelWidth
            : width - 20;
        const matchAvailableHeight = height;

        let videoWidth = matchAvailableWidth;
        let videoHeight = matchAvailableHeight;

        if ((matchAvailableWidth * 450) / 800 > matchAvailableHeight) {
          videoWidth = (videoHeight * 800) / 450;
        } else {
          videoHeight = (videoWidth * 450) / 800;
        }
        const playlistAvailableHeight = height;
        const playistAvailableWidth = width;

        let playlistVideoHeight = playlistAvailableHeight;
        let playlistVideoWidth = playistAvailableWidth;
        if ((playlistAvailableHeight * 800) / 450 > playistAvailableWidth) {
          playlistVideoHeight = (playlistVideoWidth * 450) / 800;
        } else {
          playlistVideoWidth = (playlistVideoHeight * 800) / 450;
        }

        return {
          ...state,
          videoHeight,
          videoWidth,
          playlistVideoWidth,
          playlistVideoHeight,
          windowWidth: width,
          windowHeight: height,
          showContentInColumn,
          scrollHeight,
        };
      }
      case CANCEL_UI_COMPONENTS:
        return {
          ...state,
          ...closeAllOnScreenModalContainers,
        };
      case SHOW_ON_SCREEN_PANEL:
        return {
          ...state,
          showOnScreenSidePanel: action.payload.showOnScreenSidePanel,
        };
      case TOGGLE_ACTIONS_TABLE:
        return {
          ...state,
          showOnScreenSidePanel: !state.showOnScreenSidePanel,
          tabs: [
            ...state.tabs.map((tab) => {
              return {
                ...tab,
                selected: tab.tabId === "stats",
                subTabs: tab.subTabs.map((subTab) => {
                  return {
                    ...subTab,
                    selected: subTab.subTabId === "actions",
                  };
                }),
              };
            }),
          ],
        };
      case TAB_PANEL_LEFT: {
        const selectedTopLevelTab = state.tabs.find((tab) => tab.selected);
        if (!selectedTopLevelTab) return { ...state };
        const subTabs = selectedTopLevelTab.subTabs;
        const secondLevelTabsIndex = subTabs.findIndex((t) => t.selected);
        const newTabIndex =
          secondLevelTabsIndex > 0
            ? secondLevelTabsIndex - 1
            : subTabs.length - 1;
        return {
          ...state,
          tabs: [
            ...state.tabs.map((tab) => {
              return {
                ...tab,
                subTabs: subTabs.map((subTab, index) => {
                  return {
                    ...subTab,
                    selected: index === newTabIndex,
                  };
                }),
              };
            }),
          ],
        };
      }
      case TAB_PANEL_RIGHT: {
        const selectedTopLevelTab = state.tabs.find((tab) => tab.selected);
        if (!selectedTopLevelTab) return { ...state };
        const subTabs = selectedTopLevelTab.subTabs;
        const secondLevelTabsIndex = subTabs.findIndex((t) => t.selected);
        const newTabIndex =
          secondLevelTabsIndex < subTabs.length - 1
            ? secondLevelTabsIndex + 1
            : 0;
        return {
          ...state,
          tabs: [
            ...state.tabs.map((tab) => {
              return {
                ...tab,
                subTabs: subTabs.map((subTab, index) => {
                  return {
                    ...subTab,
                    selected: index === newTabIndex,
                  };
                }),
              };
            }),
          ],
        };
      }
      case HIDE_WIZZARD:
        return { ...state, hideWizzard: action.payload.hideWizzard };
      default:
        console.warn("UNKNOWN UISTATE REDUCER TYPE", action.type);
        return state;
    }
  }
  return [state, dispatch];
}
