async function client(endpoint, customConfig = {}, token) {
  const config = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    ...customConfig,
  };
  if (token) {
    config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
  }
  const fetchUrl = `${process.env.REACT_APP_SERVER_URL}${endpoint}`;
  return window.fetch(fetchUrl, config).then((response) => response.json());
}

export { client };
