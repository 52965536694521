const routes = [
  {
    id: "home",
    label: "Home",
    href: "/",
    hideInFooter: true,
    hideInHeader: true,
    title: "korfball.io - A better way to enjoy korfball",
    description: "Korfball data capture and visualisation from online video",
    url: "https://www.korfball.io",
  },
  {
    id: "players",
    label: "Players",
    href: "/players",
    hideInFooter: true,
    title: "Players - korfball.io",
    description: "View all players and watch shots and goals playlists",
    url: "https://www.korfball.io/players",
  },
  {
    id: "teams",
    label: "Teams",
    href: "/teams",
    hideInFooter: true,
    title: "Teams - korfball.io",
    description: "View team information and watch playlists",
    url: "https://www.korfball.io/teams",
  },
  {
    id: "competitions",
    label: "Competitions",
    href: "/competitions",
    hideInFooter: true,
    title: "Competitions - korfball.io",
    description: "View competitions",
    url: "https://www.korfball.io/competitions",
  },
  {
    id: "teamsExplorer",
    label: "Teams",
    href: "/teams-explorer",
    hideInFooter: true,
    hideInHeader: false,
    hideInSideDrawer: false,
    showInChartsMenu: true,
    title: "Teams explorer - korfball.io",
    description: "Compare teams and explore base statistics",
    url: "https://www.korfball.io/teams-explorer",
  },
  {
    id: "timeToShot",
    label: "Time to shot",
    href: "/time-to-shot",
    hideInFooter: true,
    hideInHeader: true,
    hideInSideDrawer: true,
    showInChartsMenu: false,
    title: "Time to shot explorer - korfball.io",
    description: "Explore timing of shots",
    url: "https://www.korfball.io/time-to-shot",
  },
  {
    id: "player",
    label: "Player information",
    href: "/player",
    hideInFooter: true,
    hideInHeader: true,
    hideInSideDrawer: true,
    setHelmetOnPage: true,
    getMetaDetails: {
      data({ player, playlistObject }) {
        return {
          title: `${player.playerName}${
            playlistObject ? ` - ${playlistObject.type}s playlist` : ""
          }`,
          description: `${player.playerName} information and playlists`,
          url: `https://www.korfball.io/player/${player.playerUrl}`,
        };
      },
    },
  },
  {
    id: "wkPlayer",
    label: "Player information from worldkorfball.sport",
    href: "/wkPlayer",
    hideInFooter: true,
    hideInHeader: true,
    hideInSideDrawer: true,
    setHelmetOnPage: true,
    getMetaDetails: {
      data({ player }) {
        return {
          title: `${player.playerName}`,
          description: `${player.playerName} information from worldkorfball.sport`,
          url: `https://www.korfball.io/wkPlayer/${player.playerUrl}`,
        };
      },
    },
  },
  {
    id: "wkTeam",
    label: "Team information from worldkorfball.sport",
    href: "/wkTeam",
    hideInFooter: true,
    hideInHeader: true,
    hideInSideDrawer: true,
    setHelmetOnPage: true,
    getMetaDetails: {
      data({ team }) {
        return {
          title: `${team.teamName}`,
          description: `${team.teamName} information from worldkorfball.sport`,
          url: `https://www.korfball.io/wkPlayer/${team.teamUrl}`,
        };
      },
    },
  },
  {
    id: "playersExplorer",
    label: "Players",
    href: "/players-explorer",
    hideInFooter: true,
    hideInHeader: false,
    hideInSideDrawer: false,
    showInChartsMenu: true,
    title: "Players explorer - korfball.io",
    description: "Compare teams and explore base statistics",
    url: "https://www.korfball.io/players-explorer",
  },
  {
    id: "liveCaptureHome",
    label: "Live capture",
    hideInFooter: true,
    hideInHeader: true,
    hideInSideDrawer: true,
    showInChartsMenu: false,
    href: "/live-capture-home",
    title: "Live capture - korfball.io",
    description: "Create and capture data on korfball matches",
    url: "https://www.korfball.io/live-capture-home",
  },
  {
    id: "matches",
    label: "Matches",
    href: "/matches",
    hideInFooter: true,
    title: "Matches - korfball.io",
    description: "View matches with playlists and statistics",
    url: "https://www.korfball.io/matches",
  },
  {
    id: "about",
    label: "About",
    href: "/about",
    title: "About korfball.io",
    description: "Description of the functionality of korfball.io",
    url: "https://www.korfball.io/about",
  },
  {
    id: "contact",
    label: "Contact",
    href: "/contact",
    hideInHeader: true,
    title: "Contact korfball.io",
    description: "How to contact korfball.io",
    url: "https://www.korfball.io/contact",
  },
  {
    id: "dashboard",
    label: "Dashboard",
    href: "/dashboard",
    title: "Dashboard - korfball.io",
    description: "Dashboard - create new manages and manage my data",
    url: "https://www.korfball.io/dashboard",
    hideInHeader: true,
    hideInFooter: true,
    hideInSideDrawer: true,
  },
  {
    id: "adminCompetition",
    label: "Admin competition",
    href: "/admin-competition",
    title: "Competition admin - korfball.io",
    description: "Manage competition data",
    url: "https://www.korfball.io/admin-competition",
    hideInHeader: true,
    hideInFooter: true,
    hideInSideDrawer: true,
  },
  // {
  //   id: "register",
  //   label: "Register",
  //   href: "/register",
  //   hideInHeader: true,
  //   hideInSideDrawer: true,
  //   hideWhenAuthenticated: true,
  //   title: "Create account with korfball.io",
  //   description: "Register for a free account on korfball.io",
  //   url: "https://www.korfball.io/register",
  // },
  {
    id: "privacy",
    label: "Privacy",
    href: "/privacy",
    hideInHeader: true,
    title: "Privacy policy",
    description: "Korfball.io privacy policy",
    url: "https://www.korfball.io/privacy",
  },
  {
    id: "pageNotFound",
    label: "Page not found",
    href: "/page-not-found",
    hideInHeader: true,
    hideInSideDrawer: true,
    hideInFooter: true,
    title: "Page not found",
    description: "Korfball.io - page not found",
    url: "https://www.korfball.io/page-not-found,",
  },
  {
    id: "referee",
    label: "Referees",
    href: "/referee",
    hideInHeader: true,
    hideInSideDrawer: true,
    hideInFooter: true,
    title: "Referee information",
    description: "Referees",
    url: "https://www.korfball.io/referee",
  },
  {
    id: "commentaryTraining",
    label: "Commentary training",
    href: "/commentary-training",
    hideInFooter: true,
    hideInHeader: true,
    hideInSideDrawer: true,
    title: "Commentary training - korfball.io",
    description: "Introduction to Korfball Commentary - Training course",
    url: "https://www.korfball.io/commentary-training",
  },
];

export default routes;
