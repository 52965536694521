export const defaultUiState = {
  videoWidth: 0,
  videoHeight: 0,
  matchSidePanelWidth: 550,

  homePageSettings: {
    showMatchesExplorer: true,
    showCompetitionsExplorer: true,
    showFeaturedPlayersList: false,
  },

  hideMatchPanelTopLevelTabs: true,

  showContentInColumnBreakpoint: 780,
  showContentInColumn: false,
  matchViewMode: "withSidePanel",
  // matchViewMode: "withSidePanel",
  pageMode: "view", // vs capture
  captureMode: "initial", //vs enhanced
  scrollTop: 0,
  menuIconSize: 42,
  showTooltips: true,
  navigationMenuWidth: 500,
  wizzardContainerWidth: 400,
  mainMenuWidth: 700,
  sideDrawerOpen: false,

  currentViewPanelType: null,
  showSkipSettings: false,
  showNavigationSelector: false,
  showWizzardMenu: false,
  showPendingSubs: false,
  showKeyboardShortcuts: false,
  showConfigTeams: false,
  showEditActionPanel: false,
  editActionPanelActionId: null,
  hideWizzard: false,
  showOnScreenSidePanel: false,
  inputsBackgroundColour: "black",
  inputsTextColour: "white",

  commentaryStyles: [
    {
      playerText: "textColour",
      shirtNumberBackground: "colour",
      shirtNumberText: "textColour",
      playerBorderRadius: 0,
      playerBorderBottom: "textColour",
      teamText: "textColour",
      teamBackground: "colour",
      teamBorder: "colour",
    },
  ],
  tabs: [
    {
      tabId: "stats",
      label: "live stats",
      tabIcon: "statsIcon",
      selected: true,
      subTabs: [
        {
          subTabId: "summary",
          label: "summary",
          tabIcon: "summaryIcon",
          selected: true,
        },
        {
          subTabId: "players",
          label: "players",
          tabIcon: "playersIcon",
        },
        {
          subTabId: "divisions",
          label: "divisions",
          tabIcon: "divisionsIcon",
        },
        {
          subTabId: "charts",
          label: "charts",
          activeSubTabFilter: "multiple",
          subTabFilters: ["multiple", "shotsMap"],
          filterLabels: ["match charts", "shot map"],
          tabIcon: "chartsIcon",
        },
        {
          subTabId: "actions",
          label: "actions",
          activeSubTabFilter: "feed",
          subTabFilters: ["feed", "goals", "shots", "subs"],
          tabIcon: "actionsIcon",
        },
      ],
    },
    {
      tabId: "playlist",
      label: "playlists",
      tabIcon: "playlistIcon",
      subTabs: [
        {
          subTabId: "shots",
          label: "shots",
          selected: true,
        },
        {
          subTabId: "attacks",
          label: "attacks",
        },
        {
          subTabId: "players",
          label: "players",
        },
        { subTabId: "referee", label: "referee" },
        { subTabId: "highlights", label: "highlights" },
      ],
    },
    {
      tabId: "enhance",
      label: "enhance",
      tabIcon: "enhanceIcon",
      subTabs: [],
      hideWhenMatchViewModeNotCapture: true,
    },
  ],
  dashboardTabs: [
    {
      tabId: "dataCapture",
      label: "Data capture",
      tabIcon: "statsIcon",

      subTabs: [
        {
          subTabId: "myMatches",
          label: "My matches",
          tabIcon: "summaryIcon",
        },
        {
          subTabId: "incompleteMatches",
          label: "Incomplete",
          tabIcon: "playersIcon",
        },
        {
          subTabId: "captureNewMatch",
          label: "Capture new match",
          tabIcon: "createIcon",
          selected: true,
        },
      ],
    },
    {
      tabId: "commentary",
      label: "Commentary",
      tabIcon: "microphoneIcon",
      subTabs: [
        {
          subTabId: "createNewCommentaryMatch",
          label: "Create commentary match",
          tabIcon: "createIcon",
          selected: true,
        },
        {
          subTabId: "myMatches",
          label: "Recent matches",
          tabIcon: "summaryIcon",
        },
      ],
    },
    {
      tabId: "admin",
      label: "Admin",
      tabIcon: "settingsIcon",
      selected: true,
      subTabs: [
        {
          subTabId: "users",
          label: "Users",
          tabIcon: "createIcon",
        },
        {
          subTabId: "manageCompetitions",
          label: "Manage competitions",
          tabIcon: "createIcon",
          selected: true,
          activeSubTabFilter: "current",
          subTabFilters: ["current", "new"],
          filterLabels: ["imported", "not imported"],
        },
        {
          subTabId: "messages",
          label: "Messages",
          tabIcon: "summaryIcon",
        },
      ],
      isAdminOnly: true,
    },
  ],
  liveCaptureMatchTabs: [
    {
      tabId: "input",
      label: "input",
      tabIcon: "inputIcon",
      selected: true,
      subTabs: [],
    },
    {
      tabId: "summary",
      label: "summary",
      tabIcon: "summaryIcon",
      subTabs: [],
    },
    {
      tabId: "players",
      label: "players",
      tabIcon: "playersIcon",
      subTabs: [],
    },
  ],
  liveCaptureHomeTabs: [
    {
      tabId: "match",
      label: "Data capture",
      tabIcon: "statsIcon",
      selected: true,
      subTabs: [],
    },
    {
      tabId: "commentary",
      label: "Commentary",
      tabIcon: "microphoneIcon",
      subTabs: [],
    },
    {
      tabId: "admin",
      label: "Admin",
      tabIcon: "settingsIcon",
      subTabs: [],
      isAdminOnly: true,
    },
  ],
  competitionTabs: [
    {
      tabId: "matches",
      label: "Matches",
      tabIcon: "playlistIcon",
      selected: true,
      subTabs: [],
    },
    {
      tabId: "players",
      label: "Players",
      tabIcon: "playersIcon",
      subTabs: [],
    },
    {
      tabId: "pools",
      label: "Pools",
      tabIcon: "poolsIcon",
      subTabs: [],
    },
    {
      tabId: "ranking",
      label: "Ranking",
      tabIcon: "actionsIcon",
      subTabs: [],
    },
  ],
  klCompetitionTabs: [
    {
      tabId: "matches",
      label: "Matches",
      tabIcon: "playlistIcon",
      selected: true,
      subTabs: [],
    },
    {
      tabId: "players",
      label: "Players",
      tabIcon: "playersIcon",
      subTabs: [],
    },
    {
      tabId: "divisions",
      label: "divisions",
      tabIcon: "divisionsIcon",
      subTabs: [],
    },
  ],
  teamTabs: [
    {
      tabId: "statistics",
      label: "statistics",
      tabIcon: "statsIcon",
      selected: true,
      subTabs: [],
    },
    {
      tabId: "players",
      label: "Players",
      tabIcon: "playersIcon",
      subTabs: [],
    },
    {
      tabId: "matches",
      label: "Matches",
      tabIcon: "playlistIcon",
      subTabs: [],
    },

    {
      tabId: "divisions",
      label: "divisions",
      tabIcon: "divisionsIcon",
      subTabs: [],
    },
  ],
  commentaryMatchTabs: [
    {
      tabId: "liveMatch",
      label: "Live",
      tabIcon: "communication",
      selected: true,
      subTabs: [],
    },
    {
      tabId: "dashboard",
      label: "Dashboard",
      tabIcon: "statsIcon",
      subTabs: [],
    },
    {
      tabId: "players",
      label: "Players",
      tabIcon: "playersIcon",
      subTabs: [],
    },
    {
      tabId: "history",
      label: "History",
      tabIcon: "historyIcon",
      subTabs: [],
    },
    {
      tabId: "feed",
      label: "Feed",
      tabIcon: "actionsIcon",
      subTabs: [],
    },
  ],
  viewPanelTypes: [
    {
      type: "summary",
      filters: ["slider"],
    },
    {
      type: "players",
      filters: ["teams", "slider"],
    },
    {
      type: "divisions",
      filters: ["slider"],
    },
    {
      type: "charts",
      filters: ["slider"],
    },
    {
      type: "actions",
      viewPanelTypeOptions: ["feed", "goals", "shots", "subs"],
      currentViewPanelTypeOption: "feed",
      filters: ["teams", "slider"],
    },
    {
      type: "config",
    },
  ],
  dataTypeTabs: [
    {
      tabId: "ikf",
      label: "ikf",
      tabIcon: "ikfIcon",
      subTabs: [],
      selected: true,
    },
    {
      tabId: "tagged",
      label: "video tagged",
      tabIcon: "playlistIcon",
      subTabs: [],
    },

    // {
    //   tabId: "kl",
    //   label: "kl",
    //   tabIcon: "klIcon",
    //   subTabs: [],
    // },
  ],
  adminCompetitionTabs: [
    {
      tabId: "matches",
      label: "Matches",
      tabIcon: "playlistIcon",
      selected: true,
      subTabs: [],
    },
    {
      tabId: "messages",
      label: "Messages",
      tabIcon: "communication",
      subTabs: [],
    },
    {
      tabId: "details",
      label: "details",
      tabIcon: "editIcon",
      subTabs: [],
    },
  ],

  currentModalObjects: [],
  showLastActionModal: false,
  showWizzardAction: true,
  showFilteredActionsModal: false,
  currentLastActionModelId: null,
  defaultNumberOfActions: 25,
  showWhistlesInFeed: true,
  buttonWidthDefault: 65,
  buttonHeightDefault: 60,
  captureFromVideo: true,
  activeDivisionLabel: null,
  activeDivisions: ["homeA", "awayD", "awayA", "homeD"],
  divisionsFilter: "homeA",
  divisionViewType: "players",
  activeActionId: null,
  editActionObject: null,
  editActionClone: null,
  editActionCommentObject: null,
  minChartWidth: 200,
  panelWidth: 450,
  showScoreboard: true,
  showYoutubeControls: true,
  appBackgroundColour: "#fff",
  tooltipElementId: null,
  tooltipContent: null,
  showGenderIcon: true,
  actionsListFeedLength: 10,
  showMainMenu: false,
  showMainContainer: true,
  lockShowContainer: false,
  showHeader: true,
  showFooter: true,
  maxModalHeight: 500,
  currentKey: null,
  currentKeyboardContext: "video",
  showTutorialMode: false,
};
