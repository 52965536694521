import styled from "styled-components";

const IconWrapper = styled.svg`
  display: flex;
  color: ${(props) => props.textColour};
  fill: ${(props) => props.colour};
  cursor: ${(props) =>
    props.notClickable ? "default" : props.grabbable ? "grab" : "pointer"};
  height: ${(props) => (props.size ? `${props.size}px` : "14px")};
  width: ${(props) => (props.size ? `${props.size}px` : "14px")};
  margin: 2px;
  margin-left: ${(props) => (props.right ? "auto" : "")};
  padding: 2px;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`;

export default IconWrapper;
