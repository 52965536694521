import React from "react";
import styled from "styled-components";

const Backdrop = ({ closeFunction, backdropSuperDark }) => {
  return (
    <Wrapper
      onClick={() => closeFunction(false)}
      backdropSuperDark={backdropSuperDark}
    />
  );
};

export default Backdrop;

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: ${(props) =>
    props.backdropSuperDark ? "rgba(0, 0, 0, 0.8)" : "rgba(0, 0, 0, 0.5)"};
  z-index: 1000;
`;
