import React from "react";
import styled from "styled-components";
import routes from "../../data/routes";
import { Link } from "react-router-dom";

import { useUser } from "../../context/UserContext";
const Footer = () => {
  const { isAuthenticated } = useUser();
  return (
    <Container>
      <LeftSide>
        <NavLinks>
          {routes
            .filter(
              (link) =>
                !link.hideInFooter &&
                (!link.hideWhenAuthenticated || !isAuthenticated)
            )
            .map((link) => {
              return (
                <NavLink key={link.href}>
                  <StyledLink to={link.href}>{link.label}</StyledLink>
                </NavLink>
              );
            })}
        </NavLinks>
      </LeftSide>
      <RightSide>Copyright © 2023 korfball.io</RightSide>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 95%;
  color: ${(props) => props.theme.colours.mainColour};
  padding: 0 10px;
  margin: 0 auto;
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
`;

const LeftSide = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const RightSide = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: auto;
`;

const NavLink = styled.div`
  padding: 0 0.5rem;
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colours.mainColour};
  text-decoration: none;
  &:hover,
  &:active {
    text-decoration: none;
    outline: none;
  }
`;
