import { React, createContext, useContext } from "react";
import useUiStateHook from "./useUiState";

const UiStateContext = createContext();
const DispatchUiStateContext = createContext();

export function useUiState() {
  return useContext(UiStateContext);
}

export function useDispatchUiState() {
  return useContext(DispatchUiStateContext);
}

export function UiStateProvider({ children }) {
  const [uiState, dispatchUiState] = useUiStateHook();
  return (
    <UiStateContext.Provider value={uiState}>
      <DispatchUiStateContext.Provider value={dispatchUiState}>
        {children}
      </DispatchUiStateContext.Provider>
    </UiStateContext.Provider>
  );
}
