import React from "react";
import { ThemeProvider } from "styled-components";
import { uiConfig } from "./data/uiConfig";

const getFontScale = ({ windowWidth }) => {
  switch (true) {
    case windowWidth < 500: {
      return 0.65;
    }
    case windowWidth < 600: {
      return 0.7;
    }
    case windowWidth < 650: {
      return 0.75;
    }
    case windowWidth < 700: {
      return 0.8;
    }
    case windowWidth < 800: {
      return 0.85;
    }
    case windowWidth < 900: {
      return 0.9;
    }
    case windowWidth < 1000: {
      return 0.95;
    }
    default: {
      return 1;
    }
  }
};

const Theme = ({ children, uiState }) => {
  const fontSizes = { ...uiConfig.commentaryFontSizes };
  const fontScale = getFontScale({ windowWidth: uiState.windowWidth });
  const fontEntries = Object.entries(fontSizes);
  for (const [key, value] of fontEntries) {
    fontSizes[key] = value * fontScale;
  }
  return (
    <ThemeProvider
      theme={{
        ...uiConfig,
        commentary: {
          colours: { ...uiConfig.commentaryDarkMode },
          fontSizes: { ...fontSizes },
        },
      }}
    >
      {children}
    </ThemeProvider>
  );
};
export default Theme;
