import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const CookieBanner = ({ handleCookieConsentChoice }) => {
  return (
    <Container>
      <LeftSide>
        <span>
          korfball.io uses cookies to enhance your experience of using this
          website.
          <StyledLink to="/privacy">Click here for more information</StyledLink>
        </span>
      </LeftSide>
      <ButtonsWrapper>
        <ButtonWrapper
          data-test-id="manageCookiesButton"
          onClick={() => {
            handleCookieConsentChoice("manage");
          }}
        >
          <Text>Manage cookies</Text>
        </ButtonWrapper>
        <ButtonWrapper
          data-test-id="acceptCookiesButton"
          onClick={() => {
            handleCookieConsentChoice("accept");
          }}
        >
          <Text>Accept all</Text>
        </ButtonWrapper>
      </ButtonsWrapper>
    </Container>
  );
};

export default CookieBanner;

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.colours.navbarBackground};
  color: ${(props) => props.theme.colours.navbarColour};
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  z-index: 1000000;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    margin-top: 29px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  height: 30px;
  margin: 10px;
  align-items: center;
  font-size: 22px;
  border: 1px solid ${(props) => props.theme.colours.whiteOpacity20};
  border-radius: 3px;
  cursor: pointer;
  padding: 15px;
  &:hover {
    border-color: white;
  }
`;

const LeftSide = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledLink = styled(Link)`
  display: inline;
  color: ${(props) => props.theme.colours.navbarColour};
  text-decoration: underline;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  &:hover,
  &:active {
    outline: none;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  user-select: none;
  white-space: nowrap;
`;
