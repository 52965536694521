import React, { useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import SideDrawer from "./components/base/SideDrawer";
import Backdrop from "./components/base/Backdrop";
import Footer from "./components/base/Footer";
import Navbar from "./components/base/Navbar";

import LayoutRoutes from "./LayoutRoutes";

const Layout = () => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const toggleSideDrawer = () => {
    setSideDrawerOpen((state) => !state);
  };
  const location = useLocation();
  const pathName = location ? location.pathname.toLowerCase() : null;
  const fullScreen = pathName?.includes("fullscreen");
  const hideHeaderAndFooter = pathName
    ? pathName.includes("match-view") ||
      pathName.includes("match-capture") ||
      pathName.includes("commentary-match") ||
      pathName.includes("live-capture") ||
      pathName.includes("/playlist")
    : false;
  return (
    <Container fullScreen={fullScreen}>
      {!hideHeaderAndFooter && (
        <SideDrawer
          sideDrawerOpen={sideDrawerOpen}
          toggleSideDrawer={toggleSideDrawer}
        />
      )}
      {!hideHeaderAndFooter && sideDrawerOpen && (
        <Backdrop closeFunction={setSideDrawerOpen} />
      )}
      {!hideHeaderAndFooter && <Navbar toggleSideDrawer={toggleSideDrawer} />}
      <InnerPageContainer>
        <LayoutRoutes />
      </InnerPageContainer>
      {!hideHeaderAndFooter && <Footer />}
    </Container>
  );
};

export default Layout;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colours.mainBackground};
  color: ${(props) => props.theme.colours.mainColour};
  min-height: ${(props) => (props.fullScreen ? "100vh" : "calc(100vh)")};
  flex-grow: 1;
  margin: 0 auto;
  padding-right: 0;
  width: 100vw;
  @media (${(props) => props.theme.device.tablet}) {
    padding: ${(props) => (props.fullScreen ? "0 5px" : "0 10px")};
    width: ${(props) => (props.fullScreen ? "100vw" : "calc(100vw - 30px)")};
  }
`;

const InnerPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 70px);
  width: 100%;
  flex-grow: 1;
`;
