import React from "react";
import { Helmet } from "react-helmet-async";

import routes from "../../data/routes";

const baseUrl = "https://www.korfball.io";

export default function SEO(props) {
  let { title, description, url, imageUrl, pathname } = props;
  if (pathname) {
    const baseRoute = routes.find(
      (r) => r.href === pathname && !r.setHelmetOnPage
    );
    if (baseRoute) {
      title = baseRoute.title;
      description = baseRoute.description;
    } else {
      return null;
    }
  }
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={`${baseUrl}${url}`} />
      <meta
        property="og:image"
        content={
          imageUrl
            ? imageUrl
            : "https://korfdata.s3.eu-central-1.amazonaws.com/images/helmet/metaHomeImage.jpg"
        }
      />
      <meta name="twitter:creator" content="korfball.io" />
      <meta name="twitter:card" content="article" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
}
//"https://korfdata.s3.eu-central-1.amazonaws.com/images/helmet/metaHomeImage.jpg"

// `https://korfdata.s3.eu-central-1.amazonaws.com/images/matches/${match.wkMatchId}.jpg`
