export const SET_SHOW_FULL_VIDEO = "SET_SHOW_FULL_VIDEO";
export const UPDATE_AFTER_WINDOW_SIZE_CHANGED =
  "UPDATE_AFTER_WINDOW_SIZE_CHANGED";
export const HANDLE_NAVIGATION_ICON_CLICK = "HANDLE_NAVIGATION_ICON_CLICK";
export const CLOSE_NAVIGATION_CONTROLLER = "CLOSE_NAVIGATION_CONTROLLER";
export const HANDLE_NAVIGATION_TEAM_CLICK = "HANDLE_NAVIGATION_TEAM_CLICK";
export const SET_FOCUSED_META_PLAYLIST = "SET_FOCUSED_META_PLAYLIST";

export const SET_VIDEO_POSITION = "SET_VIDEO_POSITION";

export const HANDLE_VIEW_ICON_CLICK = "HANDLE_VIEW_ICON_CLICK";
export const SET_PANEL_MENU_OPTION = "SET_PANEL_MENU_OPTION";
export const CLOSE_PANEL_MODAL = "CLOSE_PANEL_MODAL";

export const CREATE_MODAL_OBJECT = "CREATE_MODAL_OBJECT";
export const TOGGLE_MODAL_OBJECT = "TOGGLE_MODAL_OBJECT";
export const REMOVE_MODAL_OBJECT = "REMOVE_MODAL_OBJECT";
export const UPDATE_MODAL_OBJECT_BY_FUNCTION =
  "UPDATE_MODAL_OBJECT_BY_FUNCTION";

export const SHOW_LAST_ACTION = "SHOW_LAST_ACTION";
export const SHOW_EDIT_LAST_ACTION = "SHOW_EDIT_LAST_ACTION";
export const SET_SHOW_MAIN_MENU = "SET_SHOW_MAIN_MENU";

export const HIDE_TOP_LEVEL_MATCH_TABS = "HIDE_TOP_LEVEL_MATCH_TABS";

export const TAB_PANEL_LEFT = "TAB_PANEL_LEFT";
export const TAB_PANEL_RIGHT = "TAB_PANEL_RIGHT";
export const TOGGLE_ACTIONS_TABLE = "TOGGLE_ACTIONS_TABLE";
export const EDIT_LAST_ACTION = "EDIT_LAST_ACTION";
export const TOGGLE_KEYBOARD_TIPS = "TOGGLE_KEYBOARD_TIPS";
export const CANCEL_UI_COMPONENTS = "CANCEL_UI_COMPONENTS";
export const TOGGLE_SHOW_WIZZARD_MENU = "TOGGLE_SHOW_WIZZARD_MENU";
export const TOGGLE_SHOW_PENDING_SUBS = "TOGGLE_SHOW_PENDING_SUBS";
export const TOGGLE_SHOW_EDIT_ACTION_PANEL = "TOGGLE_SHOW_EDIT_ACTION_PANEL";
export const TOGGLE_SHOW_KEYBOARD_SHORTCUTS = "TOGGLE_SHOW_KEYBOARD_SHORTCUTS";

export const SET_ELEMENT_POSITION = "SET_ELEMENT_POSITION";

export const TOGGLE_SHOW_TOOLTIPS = "TOGGLE_SHOW_TOOLTIPS";

export const SET_MATCH_VIEW_MODE = "SET_MATCH_VIEW_MODE";
export const SET_PLAYLIST_VIEW_MODE = "SET_PLAYLIST_VIEW_MODE";
export const SET_PAGE_MODE = "SET_PAGE_MODE";
export const SET_SHOW_TUTORIAL_MODE = "SET_SHOW_TUTORIAL_MODE";

export const SET_SELECTED_TAB_ID = "SET_SELECTED_TAB_ID";
export const SET_SELECTED_SUB_TAB_ID = "SET_SELECTED_SUB_TAB_ID";
export const SET_SELECTED_SUB_TAB_FILTER = "SET_SELECTED_SUB_TAB_FILTER";

export const SET_DIVISIONS_FILTER = "SET_DIVISIONS_FILTER";
export const SET_CAPTURE_MODE = "SET_CAPTURE_MODE";
export const TOGGLE_SHOW_HELP = "TOGGLE_SHOW_HELP";
export const TOGGLE_SHOW_ALL_DIVISIONS = "TOGGLE_SHOW_ALL_DIVISIONS";
export const TOGGLE_SHOW_SKIP_SETTINGS = "TOGGLE_SHOW_SKIP_SETTINGS";
export const TOGGLE_SHOW_NAVIGATION_SELECTOR =
  "TOGGLE_SHOW_NAVIGATION_SELECTOR";

export const SET_SCREENSHOT_MODE = "SET_SCREENSHOT_MODE";
export const HIDE_WIZZARD = "HIDE_WIZZARD";
export const SHOW_ON_SCREEN_PANEL = "SHOW_ON_SCREEN_PANEL";

export const SET_SELECTED_DASHBOARD_TAB_ID = "SET_SELECTED_DASHBOARD_TAB_ID";
export const SET_SELECTED_DASHBOARD_SUB_TAB_ID =
  "SET_SELECTED_DASHBOARD_SUB_TAB_ID";
export const SET_SELECTED_DASHBOARD_SUB_TAB_FILTER =
  "SET_SELECTED_DASHBOARD_SUB_TAB_FILTER";
export const SET_SELECTED_COMMENTARY_MATCH_TAB_ID =
  "SET_SELECTED_COMMENTARY_MATCH_TAB_ID";
export const SET_SELECTED_COMMENTARY_MATCH_SUB_TAB_ID =
  "SET_SELECTED_COMMENTARY_MATCH_SUB_TAB_ID";
export const SET_SELECTED_COMPETITION_TAB_ID =
  "SET_SELECTED_COMPETITION_TAB_ID";

export const SET_DATA_TYPE_TAB_ID = "SET_DATA_TYPE_TAB_ID";
export const SET_LIVE_CAPTURE_MATCH_TAB_ID = "SET_LIVE_CAPTURE_MATCH_TAB_ID";
