import { React, createContext, useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { useAsync } from "../hooks/useAsync";
import { client } from "../api/api-client";

const UserContext = createContext();
const UpdateUserContent = createContext();

async function fetchUser({ getAccessTokenSilently, run, setDbUser }) {
  const token = await getAccessTokenSilently();
  run(client("/api/dashboard/getUser", {}, token)).then((data) => {
    setDbUser(data.dbUser);
  });
}

export function useUser() {
  return useContext(UserContext);
}

export function useUpdateUser() {
  return useContext(UpdateUserContent);
}

export function UserProvider({ children }) {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
    isLoading,
    user,
  } = useAuth0();
  const { run } = useAsync();
  const [dbUser, setDbUser] = useState();

  const updateUser = (user) => {
    setDbUser(user);
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchUser({
        getAccessTokenSilently,
        run,
        setDbUser,
      });
    }
  }, [getAccessTokenSilently, isAuthenticated, run]);

  const userContextValue = {
    user: dbUser && user ? Object.assign(dbUser, user) : null,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  };
  const userUpdateContextValue = { updateUser };

  return (
    <UserContext.Provider value={userContextValue}>
      <UpdateUserContent.Provider value={userUpdateContextValue}>
        {children}
      </UpdateUserContent.Provider>
    </UserContext.Provider>
  );
}
