import React from "react";
import ReactDOM from "react-dom/client";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import { UiStateProvider } from "./context/UiContext";
import { UserProvider } from "./context/UserContext";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import App from "./App.jsx";
import "./index.css";

const helmetContext = {};

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <CookiesProvider>
      <BrowserRouter>
        <UiStateProvider>
          <Auth0ProviderWithHistory>
            <HelmetProvider context={helmetContext}>
              <UserProvider>
                <App />
              </UserProvider>
            </HelmetProvider>
          </Auth0ProviderWithHistory>
        </UiStateProvider>
      </BrowserRouter>
    </CookiesProvider>
  </React.StrictMode>
);
