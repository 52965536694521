import React, { useState } from "react";
import styled from "styled-components";
import Switch from "react-switch";
import { Link } from "react-router-dom";

import { Cancel as CloseIcon } from "@styled-icons/material/Cancel";

// {"necessaryCookies":["__ba_*","rwe*","language","UMB_*","XSRF-*"],"optionalCookies":{},"initialState":{"type":"closed"},"statement":{"shown":true,"updated":"09/07/2020"},"consentDate":1629194003523,"consentExpiry":90,"interactedWith":true,"user":"3834397F-6E8C-4E5B-BB71-1C0844498E0F"}

const cookiesCategories = [
  {
    id: "necessary",
    active: true,
    configurable: false,
    title: "Strictly necessary cookies",
    text: `Necessary cookies enable core functionality such as security, network management, and accessibility. 
    You may disable these by changing your browser settings, but this may affect how the website functions.
    `,
  },
  {
    id: "analytics",
    active: false,
    configurable: true,
    title: "Analytics cookies",
    text: `We'd like to set Google Analytics cookies to help us improve our website by collecting and reporting information on how you use it. 
    The cookies collect information in a way that does not directly identify anyone.`,
  },
];

const ManageCookies = ({ handleCookieConsentChoice, setManageCookies }) => {
  const [cookieObjects, setCookieObjects] = useState(cookiesCategories);
  return (
    <Container>
      <Backdrop />
      <Content>
        <TopSection>
          <TitleRow>
            <TitleText>Manage cookie preferences</TitleText>
            <IconWrapper
              size={35}
              onClick={() => {
                setManageCookies(false);
              }}
            >
              <CloseIcon />
            </IconWrapper>
          </TitleRow>
          <TopText>
            We use necessary cookies to make our site work. We&apos;d also like
            to set analytics cookies that help us make improvements by measuring
            how you use the site. These will be set only if you accept.
          </TopText>
          <StyledLink to="/privacy">Click here for more information</StyledLink>
          <ButtonsWrapper>
            <ButtonWrapper
              data-test-id="acceptCookiesButton"
              onClick={() => {
                handleCookieConsentChoice("accept");
              }}
            >
              <Text>Accept all</Text>
            </ButtonWrapper>
          </ButtonsWrapper>
        </TopSection>

        {cookieObjects.map((cookieObject) => {
          return (
            <Section key={cookieObject.id}>
              <Header>
                <HeaderText>{cookieObject.title}</HeaderText>
                {cookieObject.configurable ? (
                  <ToggleSwitch
                    height={20}
                    width={40}
                    checkedIcon={true}
                    onChange={(state) => {
                      setCookieObjects(
                        cookieObjects.map((c) => {
                          if (c.id === cookieObject.id) {
                            c.active = state;
                          }
                          return c;
                        })
                      );
                    }}
                    checked={cookieObject.active}
                  />
                ) : (
                  <AlwaysSetText>Always set</AlwaysSetText>
                )}
              </Header>
              <DescriptionText>{cookieObject.text}</DescriptionText>
            </Section>
          );
        })}
        <ButtonsWrapper>
          <ButtonWrapper
            data-test-id="declineCookiesButton"
            onClick={() => {
              handleCookieConsentChoice("decline");
            }}
          >
            <Text>Reject all</Text>
          </ButtonWrapper>
          <ButtonWrapper
            data-test-id="confirmCookiesButton"
            onClick={() => {
              const acceptAnalytics = cookieObjects.reduce((acc, val) => {
                return val.id === "analytics" ? val.active : acc;
              }, false);
              handleCookieConsentChoice(acceptAnalytics ? "accept" : "decline");
            }}
          >
            <Text>Confirm choices</Text>
          </ButtonWrapper>
        </ButtonsWrapper>
      </Content>
    </Container>
  );
};

export default ManageCookies;

const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999998;
`;

const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  width: 100%;
  z-index: 99999999;
  overflow-y: auto;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 768px;
  margin: auto;
  padding: 10px;
  background-color: ${(props) => props.theme.colours.navbarBackground};
  color: ${(props) => props.theme.colours.navbarColour};
  z-index: 999999;
  line-height: 1.4;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const TitleRow = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
`;

const TitleText = styled.div`
  display: flex;
  font-size: 30px;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  font-size: 22px;
  padding: 10px;
`;

const HeaderText = styled.div`
  display: flex;
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TopText = styled.div`
  display: flex;
  padding: 10px;
  font-size: 20px;
`;

const AlwaysSetText = styled.div`
  display: flex;
  margin-left: auto;
  opacity: 0.8;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DescriptionText = styled.div`
  padding: 10px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    margin-top: 29px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  height: 30px;
  margin: 10px;
  align-items: center;
  font-size: 22px;
  border: 1px solid gray;
  border-radius: 5px;
  cursor: pointer;
  padding: 7px 5px;
  &:hover {
    border-color: white;
  }
`;

const ToggleSwitch = styled(Switch)`
  margin-top: 3px;
  margin-left: auto;
`;

const StyledLink = styled(Link)`
  display: inline;
  color: ${(props) => props.theme.colours.navbarColour};
  text-decoration: underline;
  cursor: pointer;
  margin-top: 5px;
  padding: 10px;
  &:active {
    outline: none;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  user-select: none;
  white-space: nowrap;
`;

const IconWrapper = styled.svg`
  display: flex;
  color: ${(props) => props.textColour};
  height: ${(props) => (props.size ? `${props.size}px` : "14px")};
  width: ${(props) => (props.size ? `${props.size}px` : "14px")};
  margin: 2px;
  padding: 2px;
  margin-left: auto;
  cursor: pointer;
`;
