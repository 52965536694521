import React, { lazy, Suspense } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { AuthenticatedRoute } from "./components/base/AuthenticatedRoute";

import Loading from "./components/base/Loading";
import PageNotFound from "./pages/PageNotFound";
import SEO from "./components/common/HelmetSEO";

const LiveCaptureHome = lazy(() =>
  import("./pages/liveCapture/LiveCaptureHome")
);
const LiveCaptureMatch = lazy(() =>
  import("./pages/liveCapture/LiveCaptureMatch")
);
const CommentaryMatch = lazy(() => import("./pages/CommentaryMatch"));
const ManualCommentaryMatch = lazy(() =>
  import("./pages/ManualCommentaryMatch")
);
// import NeedToLogin from "./pages/NeedToLogin";

const CustomIcons = lazy(() => import("./pages/CustomIcons"));
const Players = lazy(() => import("./pages/Players"));
const Player = lazy(() => import("./pages/Player"));
const WkPlayer = lazy(() => import("./pages/WkPlayer"));
const Match = lazy(() => import("./pages/Match"));
const Teams = lazy(() => import("./pages/Teams"));
const Team = lazy(() => import("./pages/Team"));
const TeamsExplorer = lazy(() => import("./pages/TeamsExplorer"));
const PlayersExplorer = lazy(() => import("./pages/PlayersExplorer"));
const Contact = lazy(() => import("./pages/Contact"));
const Privacy = lazy(() => import("./pages/Privacy"));
const Matches = lazy(() => import("./pages/Matches"));
const MatchStatistics = lazy(() => import("./pages/MatchStatistics"));
const MatchPlaylists = lazy(() => import("./pages/MatchPlaylists"));
const Competition = lazy(() => import("./pages/Competition"));
const Competitions = lazy(() => import("./pages/Competitions"));
const Playlist = lazy(() => import("./pages/Playlist"));
const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const CommentaryTraining = lazy(() => import("./pages/CommentaryTraining"));
const CreateVideo = lazy(() => import("./pages/CreateVideo"));
const VideoShort = lazy(() => import("./pages/VideoShort"));
const WkTeamPage = lazy(() => import("./pages/WkTeamPage"));

const KlMatchStatistics = lazy(() => import("./pages/KlMatchStatistics"));

const Dashboard = lazy(() => import("./pages/Dashboard"));
const AdminCompetition = lazy(() => import("./pages/AdminCompetition"));

const LayoutRoutes = ({ toggleSideDrawer }) => {
  const location = useLocation();
  return (
    <>
      <SEO pathname={location.pathname} url={window.document.location} />
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Loading />}>
              <Home toggleSideDrawer={toggleSideDrawer} />
            </Suspense>
          }
        />
        <Route
          path="/about"
          element={
            <Suspense fallback={<Loading />}>
              <About />
            </Suspense>
          }
        />
        <Route
          path="/players"
          element={
            <Suspense fallback={<Loading />}>
              <Players />
            </Suspense>
          }
        />
        <Route
          path="/player/:playerUrl"
          element={
            <Suspense fallback={<Loading />}>
              <Player />
            </Suspense>
          }
        />
        <Route
          path="/wkPlayer/:playerUrl/:rawCompetitionId?"
          element={
            <Suspense fallback={<Loading />}>
              <WkPlayer />
            </Suspense>
          }
        />
        <Route
          path="/video-short/:videoShortId"
          element={
            <Suspense fallback={<Loading />}>
              <VideoShort />
            </Suspense>
          }
        />
        <Route
          path="/match-playlists/:matchId"
          element={
            <Suspense fallback={<Loading />}>
              <MatchPlaylists />
            </Suspense>
          }
        />
        <Route
          path="/match-statistics/:matchId"
          element={
            <Suspense fallback={<Loading />}>
              <MatchStatistics />
            </Suspense>
          }
        />
        <Route
          path="/kl-match-statistics/:matchId"
          element={
            <Suspense fallback={<Loading />}>
              <KlMatchStatistics />
            </Suspense>
          }
        />
        <Route
          path="/match-view/:matchId"
          element={
            <Suspense fallback={<Loading />}>
              <Match pageMode="view" />
            </Suspense>
          }
        />
        <Route
          path="/match-capture/:matchId"
          element={
            <Suspense fallback={<Loading />}>
              <Match pageMode="capture" />
            </Suspense>
          }
        />
        <Route
          path="/match-commentary/:matchId"
          element={
            <Suspense fallback={<Loading />}>
              <Match pageMode="commentary" />
            </Suspense>
          }
        />
        <Route
          path="/live-capture-home"
          element={
            <Suspense fallback={<Loading />}>
              <LiveCaptureHome />
            </Suspense>
          }
        />
        <Route
          path="/live-capture-match/:matchId"
          element={
            <Suspense fallback={<Loading />}>
              <LiveCaptureMatch />
            </Suspense>
          }
        />
        <Route
          path="/live-capture-match/:matchId"
          element={
            <Suspense fallback={<Loading />}>
              <LiveCaptureMatch pageMode="capture" />
            </Suspense>
          }
        />
        <Route
          path="/matches"
          element={
            <Suspense fallback={<Loading />}>
              <Matches />
            </Suspense>
          }
        />
        <Route
          path="/competitions"
          element={
            <Suspense fallback={<Loading />}>
              <Competitions />
            </Suspense>
          }
        />
        <Route
          path="/commentary-match/:matchId/:screenMode?"
          element={
            <Suspense fallback={<Loading />}>
              <CommentaryMatch />
            </Suspense>
          }
        />
        <Route
          path="/manual-commentary-match/:matchId/:screenMode?"
          element={
            <Suspense fallback={<Loading />}>
              <ManualCommentaryMatch />
            </Suspense>
          }
        />
        <Route
          path="/commentary-training"
          element={
            <Suspense fallback={<Loading />}>
              <CommentaryTraining />
            </Suspense>
          }
        />
        <Route
          path="/teams"
          element={
            <Suspense fallback={<Loading />}>
              <Teams />
            </Suspense>
          }
        />
        <Route
          path="/team/:pathTeamName"
          element={
            <Suspense fallback={<Loading />}>
              <Team />
            </Suspense>
          }
        />
        <Route
          path="/wkTeam/:teamUrl"
          element={
            <Suspense fallback={<Loading />}>
              <WkTeamPage />
            </Suspense>
          }
        />
        <Route
          path="/competition/:competitionId"
          element={
            <Suspense fallback={<Loading />}>
              <Competition />
            </Suspense>
          }
        />
        <Route
          path="/teams-explorer"
          element={
            <Suspense fallback={<Loading />}>
              <TeamsExplorer />
            </Suspense>
          }
        />
        <Route
          path="/playlist"
          element={
            <Suspense fallback={<Loading />}>
              <Playlist />
            </Suspense>
          }
        />
        <Route
          path="/players-explorer"
          element={
            <Suspense fallback={<Loading />}>
              <PlayersExplorer />
            </Suspense>
          }
        />
        <Route
          path="/match-capture/:matchId"
          element={
            <Suspense fallback={<Loading />}>
              <Match pageMode="capture" />
            </Suspense>
          }
        />
        <Route
          path="/contact"
          element={
            <Suspense fallback={<Loading />}>
              <Contact />
            </Suspense>
          }
        />
        <Route
          path="/privacy"
          element={
            <Suspense fallback={<Loading />}>
              <Privacy />
            </Suspense>
          }
        />
        {/* <Route
          path="/need-to-log-in"
          element={
            <Suspense fallback={<Loading />}>
              <NeedToLogin />
            </Suspense>
          }
        /> */}
        <Route
          path="/dashboard"
          element={
            <Suspense fallback={<Loading />}>
              <AuthenticatedRoute>
                <Dashboard />
              </AuthenticatedRoute>
            </Suspense>
          }
        />
        <Route
          path="/admin-competition/:competitionId"
          element={
            <Suspense fallback={<Loading />}>
              <AuthenticatedRoute>
                <AdminCompetition />
              </AuthenticatedRoute>
            </Suspense>
          }
        />
        <Route
          path="/createVideo"
          element={
            <Suspense fallback={<Loading />}>
              <AuthenticatedRoute>
                <CreateVideo />
              </AuthenticatedRoute>
            </Suspense>
          }
        />
        <Route
          path="/customIcons"
          element={
            <Suspense fallback={<Loading />}>
              <CustomIcons />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<Loading />}>
              <PageNotFound />
            </Suspense>
          }
        />
      </Routes>
    </>
  );
};

export default LayoutRoutes;
