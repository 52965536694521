import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { useUser } from "../../context/UserContext";

import routes from "../../data/routes";

import { Menu as MenuIcon } from "@styled-icons/material/Menu";
import { AccountBox } from "@styled-icons/material/AccountBox";
import { KeyboardArrowUp as LessIcon } from "@styled-icons/material/KeyboardArrowUp";
import { KeyboardArrowDown as MoreIcon } from "@styled-icons/material/KeyboardArrowDown";

import UserMenu from "./UserMenu";
import IconWrapper from "../common/IconWrapper";

const Navbar = ({ toggleSideDrawer }) => {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [chartsMenuOpen, setChartsMenuOpen] = useState(false);
  const { isAuthenticated, user, isLoading } = useUser();

  return (
    <Container data-test-id="mainNav">
      <Bar>
        <LeftBar>
          <NavLink>
            <StyledLink to="/">korfball.io</StyledLink>
          </NavLink>
        </LeftBar>
        <Spacer />
        <NavLinks>
          {routes
            .filter((link) => !link.hideInHeader && !link.showInChartsMenu)
            .map((link) => {
              return (
                <NavLink uppercase key={link.href}>
                  <StyledLink to={link.href}>{link.label}</StyledLink>
                </NavLink>
              );
            })}
          <ChartsSection
            onMouseEnter={() => setChartsMenuOpen(true)}
            onMouseLeave={() => setChartsMenuOpen(false)}
          >
            <ChartLabel uppercase>Charts</ChartLabel>
            <UserMenuIcon size={26}>
              {chartsMenuOpen ? <LessIcon /> : <MoreIcon />}
            </UserMenuIcon>
            {chartsMenuOpen && (
              <ChartsMenuWrapper>
                <MenuContainer>
                  {routes
                    .filter((link) => link.showInChartsMenu)
                    .map((link) => {
                      return (
                        <MenuRow key={link.href}>
                          <StyledLink to={link.href}>{link.label}</StyledLink>
                        </MenuRow>
                      );
                    })}
                </MenuContainer>
              </ChartsMenuWrapper>
            )}
          </ChartsSection>
        </NavLinks>
        <MenuIconWrapper colour="white" size={30} onClick={toggleSideDrawer}>
          <MenuIcon />
        </MenuIconWrapper>

        <AccountSection
          onMouseEnter={() => setUserMenuOpen(true)}
          onMouseLeave={() => setUserMenuOpen(false)}
        >
          {!isLoading && isAuthenticated && user ? (
            <AccountIconWrapperLink to="/dashboard">
              <AvatarImage
                avatarImage={user.picture}
                alt="avatar"
                title="you are logged in"
                width="25px"
                height="25px"
              />
            </AccountIconWrapperLink>
          ) : (
            <AccountIconWrapper colour="white" size={30}>
              <AccountIcon />
            </AccountIconWrapper>
          )}

          <UserMenuIcon size={26}>
            {userMenuOpen ? <LessIcon /> : <MoreIcon />}
          </UserMenuIcon>
          {userMenuOpen && (
            <UserMenuWrapper>
              <UserMenu user={user} closeMenu={() => setUserMenuOpen(false)} />
            </UserMenuWrapper>
          )}
        </AccountSection>
      </Bar>
    </Container>
  );
};

export default Navbar;

const Container = styled.div`
  height: 60px;
  transition: all 0.5s;
  padding: "10px 5px";
  color: ${(props) => props.theme.colours.navbarColour};
  background: ${(props) => props.theme.colours.navbarBackground};
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 200;
  font-size: 18px;
  width: 100%;
  z-index: 10000;
`;

const AccountSection = styled.div`
  display: none;
  width: 70px;
  margin-left: 10px;
  justify-content: flex-end;
  @media (${(props) => props.theme.device.laptop}) {
    display: flex;
  }
`;

const ChartsSection = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 10px;
`;

const ChartLabel = styled.div`
  display: flex;
  text-transform: ${(prop) => (prop.uppercase ? "uppercase" : "")};
`;

const UserMenuWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 35px;
  color: ${(props) => props.theme.colours.navbarColour};
  background-color: ${(props) => props.theme.colours.navbarBackground};
`;

const ChartsMenuWrapper = styled.div`
  position: absolute;
  padding-top: 10px;
  right: 10px;
  top: 25px;
  color: ${(props) => props.theme.colours.navbarColour};
  background-color: ${(props) => props.theme.colours.navbarBackground};
`;

const MenuIconWrapper = styled(IconWrapper)`
  display: flex;
  @media (${(props) => props.theme.device.laptop}) {
    display: none;
  }
`;

const AvatarImage = styled.div`
  display: flex;
  width: 25px;
  height: 25px;
  background-image: url(${(props) => props.avatarImage});
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const AccountIconWrapperLink = styled(Link)`
  display: flex;
  width: 30px;
  text-decoration: none;
  color: ${(props) => props.theme.colours.mainColour};
`;

const AccountIconWrapper = styled.div`
  display: flex;
  width: 30px;
  color: ${(props) => props.theme.colours.mainColour};
`;

const UserMenuIcon = styled(IconWrapper)``;

const Bar = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin: auto;
  max-width: ${(props) => props.theme.layout.maxAppWidth}px;
  padding: 0 10px;
  position: relative;
`;

const NavLinks = styled.ul`
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
  @media (${(props) => props.theme.device.laptop}) {
    display: flex;
  }
`;

const Spacer = styled.div`
  flex: 1;
`;

const LeftBar = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 26px;
`;

const NavLink = styled.li`
  display: flex;
  justify-content: bottom;
  padding: 5px 15px;

  text-transform: ${(prop) => (prop.uppercase ? "uppercase" : "")};
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colours.navbarColour};
  text-decoration: none;
  user-select: none;
  &:hover,
  &:active {
    text-decoration: none;
    outline: none;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 300;
  margin: 0 auto;
`;

const MenuRow = styled.div`
  display: flex;
  padding: 10px 20px;
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};
  justify-content: center;
`;

const AccountIcon = styled(AccountBox)`
  width: 30px;
  height: 30px;
`;

// const LogoWrapper = styled.div``;
