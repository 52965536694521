import React from "react";
import { Audio } from "react-loader-spinner";
import styled from "styled-components";

const Loading = () => {
  return (
    <Container>
      <CentreWrapper>
        <Audio
          height="100"
          width="100"
          color="#4fa94d"
          ariaLabel="audio-loading"
          wrapperStyle={{}}
          wrapperClass="wrapper-class"
          visible={true}
        />
      </CentreWrapper>
    </Container>
  );
};

export default Loading;

const Container = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: ${(props) =>
    props.backdropSuperDark ? "rgba(0, 0, 0, 0.8)" : "rgba(0, 0, 0, 0.5)"};
  z-index: 1000;
`;

const CentreWrapper = styled.div`
  height: 100px;
  width: 100px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50px, -50px);
`;
