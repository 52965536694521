const CONSENT_COOKIE_EXPIRES_IN_DAYS = 90;
const DECLINE_COOKIE_EXPIRES_IN_DAYS = 14;
const CONSENT_COOKIE_NAME = "k__io_analytics-permission";

export const handleCookieConsentResponse = ({
  choice,
  setManageCookies,
  setCookie,
  removeCookie,
}) => {
  const acceptExpires = new Date(
    new Date().setDate(new Date().getDate() + CONSENT_COOKIE_EXPIRES_IN_DAYS)
  );
  const declineExpires = new Date(
    new Date().setDate(new Date().getDate() + DECLINE_COOKIE_EXPIRES_IN_DAYS)
  );

  switch (choice) {
    case "accept":
      setManageCookies(false);
      setCookie(CONSENT_COOKIE_NAME, "accepted", {
        expires: acceptExpires,
      });
      break;
    case "manage":
      setManageCookies(true);
      break;
    case "decline":
      setManageCookies(false);
      setCookie(CONSENT_COOKIE_NAME, "declined", {
        expires: declineExpires,
      });
      removeCookie("_ga");
      removeCookie("_gid");
      break;
    default:
  }
};
