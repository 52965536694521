import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import routes from "../data/routes";

const PageNotFound = () => {
  document.title = "404 - page not found - korfball.io";
  return (
    <Container>
      <HeaderText>404 - Page not found</HeaderText>
      <Text>Did you mean any of the following?</Text>
      <LinksSection>
        <MainLinks>
          {routes
            .filter((r) => !r.hideInHeader)
            .map((route) => {
              return (
                <LinkRow key={route.href}>
                  <MainLink to={route.href}>{route.label}</MainLink>
                </LinkRow>
              );
            })}
        </MainLinks>
        <OtherLinks>
          {routes
            .filter((r) => r.hideInHeader && !r.hideInSideDrawer)
            .map((route) => {
              return (
                <LinkRow key={route.href}>
                  <StyledLink to={route.href}>{route.label}</StyledLink>
                </LinkRow>
              );
            })}
        </OtherLinks>
      </LinksSection>
    </Container>
  );
};

export default PageNotFound;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  min-height: calc(100vh - 60px);
  color: ${(props) => props.theme.colours.mainColour};
  background: ${(props) => props.theme.colours.mainBackground};
  padding-top: 100px;
  width: 600px;
  font-size: 22px;
`;

const HeaderText = styled.div`
  margin-top: 30px;
  display: flex;
  font-size: 26px;
  width: 100%;
  justify-content: center;
  margin-bottom: 5px;
`;

const Text = styled.div`
  margin-top: 5px;
  display: flex;
  font-size: 18px;
  width: 100%;
  justify-content: center;
  margin-bottom: 5px;
`;

const LinksSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 100%;
  height: 100%;
`;

const MainLinks = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const OtherLinks = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: 0.9;
`;

const LinkRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 5px;
`;

const MainLink = styled(Link)`
  display: flex;
  color: ${(props) => props.theme.colours.mainColour};
  text-decoration: none;
  text-transform: uppercase;
  &:hover,
  &:active {
    text-decoration: none;
    outline: none;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  color: ${(props) => props.theme.colours.mainColour};
  text-decoration: none;
  &:hover,
  &:active {
    text-decoration: none;
    outline: none;
  }
`;
