import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export const AuthenticatedRoute = ({ children }) => {
  const { user, isLoading } = useAuth0();
  if (!user && !isLoading) {
    return <Navigate to="/need-to-log-in" />;
  }
  return children;
};
